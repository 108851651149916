<template>
    <div class="pdf-viewer">
        <embed :src="src" type="application/pdf" width="100%" height="500px" />
    </div>
</template>

<script>
    export default {
        name: 'PdfViewer',
        props: {
            src: {
                type: String,
                required: true,
            },
        },
    };
</script>

<style scoped lang="scss">
    .pdf-viewer {
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        margin-top: 0.5rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
</style>
