<template>
    <div class="image-viewer">
        <img :src="src" alt="Agreement Image" />
    </div>
</template>

<script>
    export default {
        name: 'ImageViewer',
        props: {
            src: {
                type: String,
                required: true,
            },
        },
    };
</script>

<style scoped lang="scss">
    .image-viewer {
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        margin-top: 0.5rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .image-viewer img {
        max-width: 100%;
        height: auto;
        display: block;
        border-radius: 8px;
    }
</style>
